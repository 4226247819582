/* eslint-disable no-new-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  FileTextOutlined,
  InfoCircleOutlined,
  LineOutlined,
  LoadingOutlined,
  MinusCircleOutlined,
  SearchOutlined,
  SettingOutlined,
  UploadOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Collapse,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Popover,
  Row,
  Select,
  SelectProps,
  Skeleton,
  Space,
  Switch,
  Tag,
  Tooltip,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { RcFile } from "antd/lib/upload/interface";
import React, { useEffect, useState, useRef, HTMLAttributes } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CuttingType } from "../../models/CuttingType";
import { Material } from "../../models/Material";
import { MaterialGroup } from "../../models/MaterialGroup";
import { Part } from "../../models/Part";
import { PartViewMode } from "../../models/PartViewMode";
import { SurfaceTreatment } from "../../models/SurfaceTreatment";
import { materialSelector } from "../../redux/slides/material.slide";
import partServices from "../../services/part.service";
import CurrencyFormat from "../Format/CurrencyFormat";
import PriceReference from "./PriceReference";
import "./cadFileItem.module.scss";
import {
  projectActions,
  projectSelector,
} from "../../redux/slides/project.slide";
import { RollingDirection } from "../../models/RollingDirection";
import { UserRole } from "../../models/UserRole";
import { authSelector } from "../../redux/slides/auth.slide";
import MiniReport from "./MiniReport";
import ProcessingReport from "./ProcessingReport";
import PartImage from "./PartImage";
import { Project, ProjectStatus } from "../../models/Project";
import DropdownActions from "./DropdownActions";
import { ActionMode } from "../../models/PartActionMode";
import { useTranslation } from "react-i18next";
import CadFileIcon from "../SVGs/CadFileIcon";
import PdfFileIcon from "../SVGs/PdfFileIcon";
import RobotCalc from "./RobotCalc";
import { SurfaceQuality } from "../../models/SurfaceQuality";
import { TechDrawingData } from "../../models/TechDrawingData";
import materialServices from "../../services/material.service";
import { EmissionReport } from "../../models/EmissionReport";
import DxfSelectLayer from "./DxfSelectLayer";
import SheetMetalUnfold from "../SVGs/SheetMetalUnfold";
import PriceAdaptForm from "./PriceAdaptForm";
import { configSelector } from "app/redux/slides/config.slide";
import { ShopType } from "app/models/ShopType";
import LocalizationName from "../Locale/LocalizationName";
import NumberFormat from "../Format/NumberFormat";
import CostReportModal from "./CostReportModal";
import { LaserGas } from "app/models/LaserGas";

const { Panel } = Collapse;
const { Option } = Select;

const isDxf = (fileName?: string) => {
  return !!fileName && fileName.toLowerCase().indexOf(".dxf") > -1;
};
const isDwg = (fileName?: string) => {
  return !!fileName && fileName.split(".").pop()?.toLowerCase() === "dwg";
};
const isStp = (fileName?: string) => {
  return (
    !!fileName &&
    (fileName.toLowerCase().indexOf(".step") > -1 ||
      fileName.toLowerCase().indexOf(".stp") > -1)
  );
};

interface DefaultProps extends HTMLAttributes<any> {
  file?: UploadFile<any>;
  pdfFile?: RcFile;
  type?: CuttingType;
  part?: Part;
  mode?: PartViewMode;
  pricePreview?: number;
  pricePreviewLoading?: boolean;
  onChange?: any;
  onQuantityChange?: any;
  onPartUploadedChange?: any;
}

const rollingDirections: SelectProps["options"] = [
  {
    label: "Parallel",
    value: RollingDirection.PARALLEL,
  },
  {
    label: "Quer",
    value: RollingDirection.ORTHOGONAL,
  },
  {
    label: "Egal",
    value: RollingDirection.NO_PREFERENCE,
  },
];

function CadFileItem(props: DefaultProps) {
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const auth = useSelector(authSelector);
  const config = useSelector(configSelector);
  const { t } = useTranslation();
  const { data, isCalcAll, partsStatus } = useSelector(projectSelector);
  const { projectId, accessCode } = useParams();
  const {
    type,
    file,
    pdfFile,
    mode = PartViewMode.EDIT,
    pricePreview,
    pricePreviewLoading,
    onChange,
    onQuantityChange,
    onPartUploadedChange,
    ...viewProps
  } = props;
  const dataPart: any = props.part;
  const dataCuttingType =
    props.type ||
    (config.shopType === ShopType.BOTH ||
    config.shopType === ShopType.MILLING_AND_TURNING
      ? CuttingType.MILLING_AND_TURNING
      : CuttingType.SHEET_METAL);
  const [cuttingType, setCuttingType] = useState(dataCuttingType);
  const { groups } = useSelector(materialSelector);
  const [part, setPart] = useState<Part>(dataPart);
  const materialGroups: MaterialGroup[] =
    (cuttingType === CuttingType.MILLING_AND_TURNING
      ? groups.filter((g: any) => g.materials && g.materials.length > 0)
      : groups.filter(
          (g: any) => ["Alu", "St", "VA"].findIndex((a) => a === g.abbr) > -1
        )) || [];
  const uploading = useRef(false);
  const adaptForm: any = useRef();
  const [project, setProject] = useState<Project>(data);
  const [uploaded, setUploaded] = useState(!!dataPart);
  const [parameterUploading, setParameterUploading] = useState(false);
  const [partForm] = Form.useForm();
  const [showHasTolerances, setShowHasTolerances] = useState(false);
  const [disableHasTolerences, setDisableHasTolerences] = useState(true);
  const [openMaterial, setOpenMaterial] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [file2D, setFile2D] = useState<RcFile | undefined>(pdfFile);
  const [materialGroup, setMaterialGroup] = useState(
    !!part
      ? groups.find((x: MaterialGroup) => x.id === part?.materialGroupId) ||
          undefined
      : undefined
  );
  const [material, setMaterial] = useState<Material | undefined>(
    dataPart?.material
  );
  const [groupTabSeleted, setGroupTabSeleted] = useState<MaterialGroup>();
  const [materials, setMaterials] = useState<Material[]>([]);
  const [filterMaterials, setFilterMaterials] = useState<Material[]>();
  const [filterMaterialGroups, setFilterMaterialGroups] =
    useState<MaterialGroup[]>();
  const [search, setSearch] = useState("");
  const [surfaceTreatments, setSurfaceTreatments] = useState<
    SurfaceTreatment[]
  >(materialGroup?.surfaceTreatments || []);
  const [viewMode, setViewMode] = useState<PartViewMode>(
    mode ||
      ((!!file || (part && part.totalPrice === null && part.auto)) &&
        PartViewMode.EDIT) ||
      (part &&
        (part.totalPrice !== null || !part?.auto) &&
        (project?.selfCalculation
          ? PartViewMode.ADAPT
          : PartViewMode.UPDATED)) ||
      PartViewMode.EDIT
  );
  const [referenceCosts, setReferenceCosts] = useState<any>();
  const [referenceCostsLoading, setReferenceCostsLoading] = useState(false);
  const [error, setError] = useState<any>();

  const [isQuantityEdit, setIsQuantityEdit] = useState(false);
  const [loadingQuantity, setLoadingQuantity] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [adaptPrice, setAdaptPrice] = useState<any>(
    (part?.adaptedUnitPrice || part?.unitPrice)?.toFixed(2)
  );
  const [originalPrice, setOriginalPrice] = useState(
    part?.unitPrice?.toFixed(2)
  );
  const [isOpenOriginalPrice, setIsOpenOriginalPrice] = useState(false);
  const [loadingAdaptPrice, setLoadingAdaptPrice] = useState(false);
  const [isOpenMiniReport, setIsOpenMiniReport] = useState(false);
  const [isProcessingReport, setIsProcessingReport] = useState(false);
  const [isEmissionReport, setIsEmissionReport] = useState(false);
  const [isCostReport, setIsCostReport] = useState(false);
  const [isOpenUnfoldingPreview, setIsOpenUnfoldingPreview] = useState(false);
  const [hasTechDrawingFile, setHasTechDrawingFile] = useState(false);
  const [uploadingTechDrawingFile, setUploadingTechDrawingFile] =
    useState(false);
  const [requestingTechDrawingData, setRequestingTechDrawingData] =
    useState(false);
  const [completedTechDrawingFile, setCompletedTechDrawingFile] =
    useState(false);
  const [errorTechDrawingData, setErrorTechDrawingData] = useState<any>();
  const [techDrawingData, setTechDrawingData] = useState<TechDrawingData>();
  const [emissionReport, setEmissionReport] = useState<EmissionReport>();
  const [dataPartForm, setDataPartForm] = useState<any>(part || {});
  const [selectedLayers, setSelectedLayers] = useState<any>(
    part?.dxfLayers || {}
  );
  const [checkDesignPrinciple, setcheckDesignPrinciple] = useState(true);
  const surfaceFinishs: SelectProps["options"] = [
    {
      label: t("surfaceQuality.FINELY_FINISHED"),
      value: SurfaceQuality.FINELY_FINISHED,
    },
    {
      label: t("surfaceQuality.SETTLED"),
      value: SurfaceQuality.SETTLED,
    },
    {
      label: t("surfaceQuality.ROUGHED"),
      value: SurfaceQuality.ROUGHED,
    },
  ];

  const renderToleranzen = (
    <div style={{ maxWidth: "600px" }}>
      <div>{t("part.tooltip.tolerances.message1")}</div>
      <img
        width={"100%"}
        src="/images/ToleranzmaBe_Information_Bild.jpg"
        alt="ToleranzmaBe_Information_Bild"
      ></img>
      <p>
        {t("part.numberOfThreads")} <br />1 ({t("see")} 1)
        <br />
        <br />
        {t("part.numberOfFits")}
        <br />4 ({t("see")} 2)
        <br />
        <br />
        {t("part.form.hasTolerances")}
        <br />
        {t("yes")} ({t("part.tooltip.tolerances.label")}: h5 = IT5)
      </p>
    </div>
  );

  const disableClickEvent = (event: any) => {
    event.stopPropagation();
  };

  const onRequestTechDrawingData = async (file: any) => {
    if (cuttingType === CuttingType.MILLING_AND_TURNING) {
      setRequestingTechDrawingData(true);
      setCompletedTechDrawingFile(false);
      try {
        const rs: TechDrawingData = await partServices.requestTechDrawingData(
          file
        );
        setTechDrawingData({
          ...rs,
          numOfThreads: rs.numOfThreads || 0,
          numOfTolerances: rs.numOfTolerances || 0,
          hasSmallTolerance: !!rs.hasSmallTolerance,
        });
        setCompletedTechDrawingFile(true);
      } catch (error: any) {
        // setErrorTechDrawingData(error?.message);
      }
      setRequestingTechDrawingData(false);
    }
  };

  const onUploadTechDrawingFile = async (file: any) => {
    setUploadingTechDrawingFile(true);
    try {
      await partServices.uploadTechDrawingFile({
        id: part.id,
        file,
      });
      setFile2D(undefined);
    } catch (error) {}
    setUploadingTechDrawingFile(false);
  };

  const upload2DProps: UploadProps = {
    beforeUpload: async (file: any, fileList) => {
      setFile2D(file);
      onRequestTechDrawingData(file);
      return false;
    },
    multiple: false,
    fileList: [],
    accept: `.pdf`,
  };

  const uploadFile = async () => {
    setError(undefined);
    if (!file) return;
    if (part || uploading.current || uploaded) return;
    uploading.current = true;
    dispatch(
      projectActions.setUploadPart({
        key: file.uid,
        data: null,
      })
    );
    if (pdfFile) onRequestTechDrawingData(pdfFile);
    try {
      if (file && (file.size || 0) > 1024 * 1000 * 20) {
        throw new Object({
          message: t("error.part.maxSize"),
          error: "MAX_SIZE",
        });
      }
      let ct: CuttingType = cuttingType;
      if (cuttingType !== CuttingType.MILLING_AND_TURNING) {
        if (isStp(file.name)) {
          ct = CuttingType.SHEET_METAL_STP;
        } else if (isDxf(file.name)) {
          ct = CuttingType.SHEET_METAL_DXF;
        } else if (isDwg(file.name)) {
          ct = CuttingType.SHEET_METAL_DWG;
        }
        setCuttingType(ct);
      }
      const rs: Part = await partServices.upload({
        projectId,
        cuttingType: ct,
        cadFile: file,
      });
      rs.surfaceTreatmentIds = [];
      setPart(rs);
      partForm.setFieldValue("partId", rs.id);
      setUploaded(true);
      setViewMode(PartViewMode.EDIT);
      dispatch(projectActions.setPart(rs));
      if (onPartUploadedChange) {
        onPartUploadedChange(rs);
      }
      dispatch(
        projectActions.setUploadPart({
          key: file.uid,
          data: rs,
        })
      );
      dispatch(projectActions.setPartStatus({ id: rs.id, data: viewMode }));
    } catch (error: any) {
      if (error && error.error !== "MAX_SIZE")
        error.message = t("project.error.calculation");
      setError(error);
      dispatch(
        projectActions.setUploadPart({
          key: file.uid,
          data: false,
        })
      );
    }
  };

  const updateParameters = async (values: any) => {
    setError(undefined);
    setParameterUploading(true);
    try {
      if (file2D) {
        await onUploadTechDrawingFile(file2D);
      }
      let rs: any = undefined;

      switch (part.cuttingType) {
        case CuttingType.MILLING_AND_TURNING:
          rs = await partServices.updateParameters({
            ...values,
            techDrawingData: JSON.stringify(techDrawingData),
          });
          break;
        case CuttingType.SHEET_METAL_STP:
          rs = await partServices.updateSheetMetalStpParameters(values);
          break;
        case CuttingType.SHEET_METAL_DXF:
          values.dxfLayers = selectedLayers;
          rs = await partServices.updateSheetMetalDxfParameters(values);
          break;
        case CuttingType.SHEET_METAL_DWG:
          values.dxfLayers = selectedLayers;
          rs = await partServices.updateSheetMetalDwgParameters(values);
          break;
        default:
          break;
      }
      if (rs) {
        dispatch(projectActions.setPart(rs));
        if (rs.auto && part?.materialId != values.materialId) {
          getReferenceCosts();
        }
        setViewMode(
          project?.selfCalculation ? PartViewMode.ADAPT : PartViewMode.UPDATED
        );
        setPart(rs);
        try {
          if (rs.techDrawingData) {
            const check = await partServices.checkDesignPrinciple(rs.id);
            setcheckDesignPrinciple(check);
          }
        } catch (error) {}
      }
    } catch (error: any) {
      if (error) {
        error.message = t("project.error.calculation");
        if (
          error.exception ===
          "de.gocad.exception.UserCalculationDisabledException"
        ) {
          error.message = t("project.error.calculationDisabled");
        }
      }
      setError(error);
    }
    setParameterUploading(false);
  };

  const getReferenceCosts = async () => {
    setReferenceCostsLoading(true);
    try {
      const rs = await partServices.getReferenceCosts(part?.id);
      setReferenceCosts(rs);
    } catch (error) {}
    setReferenceCostsLoading(false);
  };

  const getMaterial = async (id: number) => {
    try {
      const rs: Material = await materialServices.detail(id);
      const group = materialGroups.find((m) => m.id === rs.groupId);
      setMaterial(rs);
      setMaterialGroup(group);
    } catch (error) {}
  };

  const getEmissionReport = async () => {
    try {
      const rs = await partServices.getEmissionReport(part.id);
      setEmissionReport(rs);
    } catch (error) {}
  };

  const onSelectedLayers = (layers: any) => {
    setSelectedLayers(layers);
    partForm.setFieldValue("dxfLayers", layers);
  };

  useEffect(() => {
    if (part) {
      partForm.setFieldValue("partId", part?.id);
      partForm.setFieldValue("materialGroupId", materialGroup?.id);
    }
    uploadFile();
  }, []);
  useEffect(() => {
    if (onChange) {
      onChange(part);
    }
    if (onPartUploadedChange) {
      onPartUploadedChange(part);
    }
    if (part) {
      if (part?.auto && part?.totalPrice > 0 && !accessCode) {
        getReferenceCosts();
      }
      if (part.techDrawingData) {
        setTechDrawingData({
          ...part.techDrawingData,
          numOfThreads: part.techDrawingData.numOfThreads || 0,
          numOfTolerances: part.techDrawingData.numOfTolerances || 0,
          hasSmallTolerance: !!part.techDrawingData.hasSmallTolerance,
        });
      } else {
        setTechDrawingData(undefined);
      }

      if (
        part.cuttingType === CuttingType.SHEET_METAL_DXF ||
        part.cuttingType === CuttingType.SHEET_METAL_DWG
      ) {
        if (!part.thickness) partForm.setFieldValue("thickness", 1);
        if (!part.dxfLayers) partForm.setFieldValue("dxfLayers", {});
      }
      if (part.auto && part.unitPrice && auth.user?.role === UserRole.SELLER) {
        getEmissionReport();
      }
    }
  }, [part]);
  useEffect(() => {
    if (techDrawingData) {
      partForm.setFieldValue(
        "numberOfThreads",
        techDrawingData.numOfThreads || 0
      );
      partForm.setFieldValue(
        "numberOfFits",
        techDrawingData.numOfTolerances || 0
      );
      partForm.setFieldValue(
        "hasTolerances",
        !!techDrawingData.hasSmallTolerance
      );

      setDataPartForm({
        ...dataPartForm,
        numberOfThreads: techDrawingData.numOfThreads || 0,
        numberOfFits: techDrawingData.numOfTolerances || 0,
        hasTolerances: techDrawingData.hasSmallTolerance,
      });

      const isDisabled =
        techDrawingData?.hasSmallTolerance != null ||
        (techDrawingData?.numOfTolerances || 0) === 0;
      setDisableHasTolerences(isDisabled);

      if (techDrawingData.materialId) {
        getMaterial(techDrawingData.materialId);
      }
    }
  }, [techDrawingData]);

  useEffect(() => {
    if (!!groupTabSeleted) {
      setMaterials(
        materialGroups.find((g) => g.id === groupTabSeleted.id)?.materials || []
      );
    }
  }, [groupTabSeleted]);

  useEffect(() => {
    if (materialGroup) {
      partForm.setFieldValue(
        "surfaceTreatmentIds",
        materialGroup.id === part?.materialGroupId
          ? part?.surfaceTreatmentIds
          : []
      );
      setSurfaceTreatments(
        materialGroup.surfaceTreatments?.filter((s: any) => s.active) || []
      );
      partForm.setFieldValue("materialGroupId", materialGroup.id);
      if (materialGroup.abbr == "St") {
        partForm.setFieldValue(
          "laserGas",
          part && (part.thickness || 0) > 8 ? LaserGas.O2 : LaserGas.N2
        );
      }
    }
  }, [materialGroup]);

  useEffect(() => {
    if (material) {
      partForm.setFieldValue("materialId", material?.id);
      const g = materialGroups.find((g) =>
        g.materials?.find((m) => m.id === material?.id)
      );
      setMaterialGroup(g);
    }
  }, [material]);

  useEffect(() => {
    if (materialGroups && materials && search) {
      const rs = groupTabSeleted?.materials?.filter(
        (m) =>
          `${m.name}${m.number}`.toLowerCase().indexOf(search.toLowerCase()) >
          -1
      );
      setFilterMaterials(rs);
      const groups = materialGroups.map((g) => {
        const group = { ...g };
        group.materials =
          g.materials?.filter(
            (m) =>
              `${m.name}${m.number}`
                .toLowerCase()
                .indexOf(search.toLowerCase()) > -1
          ) || [];
        return group;
      });
      setFilterMaterialGroups(groups);
    } else {
      setFilterMaterials(undefined);
      setFilterMaterialGroups(undefined);
    }
  }, [search, groupTabSeleted]);

  useEffect(() => {
    if (isCalcAll && viewMode === PartViewMode.EDIT) {
      partForm.submit();
    }
  }, [isCalcAll]);

  useEffect(() => {
    if (part) {
      console.log(viewMode);

      dispatch(projectActions.setPartStatus({ id: part.id, data: viewMode }));
    }
  }, [viewMode]);

  const onEdit = (event: any) => {
    event.stopPropagation();
    setViewMode(PartViewMode.EDIT);
    setMaterial(part?.material);
    if (part) {
      setDataPartForm(part);
      partForm.setFieldsValue(part);
      partForm.setFieldValue("numberOfThreads", part.numberOfThreads || 0);
      partForm.setFieldValue("numberOfFits", part.numberOfFits || 0);
      partForm.setFieldValue("hasTolerances", !!part.hasTolerances);
      if (!part.dxfLayers) {
        partForm.setFieldValue("dxfLayers", {});
      }
      const isDisabled =
        part.techDrawingData?.hasSmallTolerance != null ||
        (part.techDrawingData?.numOfTolerances || part.numberOfFits || 0) === 0;
      setDisableHasTolerences(isDisabled);
    }
  };

  const onEditCancel = () => {
    setViewMode(
      project?.selfCalculation ? PartViewMode.ADAPT : PartViewMode.UPDATED
    );
    if (part && part.techDrawingData) {
      setTechDrawingData({
        ...part.techDrawingData,
        numOfThreads: part.techDrawingData.numOfThreads || 0,
        numOfTolerances: part.techDrawingData.numOfTolerances || 0,
        hasSmallTolerance: !!part.techDrawingData.hasSmallTolerance,
      });
    } else {
      setTechDrawingData(undefined);
    }
    setFile2D(undefined);
  };

  const showDeleteModal = () => {
    setOpenDelete(true);
  };

  const cancelDeleteModal = () => {
    setOpenDelete(false);
  };

  const onActionsSubmmitted = (mode: any) => {
    try {
      switch (mode) {
        case ActionMode.MOVE:
          setDeleted(true);
          api.info({
            message: `Part Move`,
            description: <>Part "{part.partFileName} moved!"</>,
            placement: "topRight",
          });
          break;
        case ActionMode.VIEW:
          break;
        default:
          break;
      }
    } catch (error) {}
  };

  const onMaterialGroupChange = (data?: MaterialGroup) => {
    if (data) {
      setGroupTabSeleted(data);
    } else {
      setGroupTabSeleted(undefined);
    }
  };

  const onMaterialSelected = (data: Material) => {
    setMaterial(data);
    partForm.setFieldValue("materialId", data.id);
    hideModal();
  };

  const onPartChange = (values: any) => {
    if (partForm.getFieldValue("thickness") > 8) {
      partForm.setFieldValue("laserGas", LaserGas.O2);
    } else {
      partForm.setFieldValue("laserGas", LaserGas.N2);
    }
    if (
      partForm.getFieldValue("hasTolerances") &&
      (partForm.getFieldValue("numberOfFits") || 0) === 0
    ) {
      partForm.setFieldValue("hasTolerances", false);
      values.hasTolerances = false;
    }
    const isDisabled =
      techDrawingData?.hasSmallTolerance != null ||
      (part?.techDrawingData?.numOfTolerances ||
        partForm.getFieldValue("numberOfFits") ||
        0) == 0;
    setDisableHasTolerences(isDisabled);
    setDataPartForm({
      ...dataPartForm,
      ...values,
    });
  };

  const showModal = () => {
    if (parameterUploading) return;
    setOpenMaterial(true);
  };

  const hideModal = () => {
    setOpenMaterial(false);
  };

  const renderPriceSymbol = (price: number) => {
    const p = Math.floor(price);
    switch (p) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        return (
          <>
            <b>€</b>€€€
          </>
        );
      case 6:
      case 7:
      case 8:
        return (
          <>
            <b>€€</b>€€
          </>
        );
      case 9:
      case 10:
      case 11:
      case 12:
      case 13:
      case 14:
      case 15:
        return (
          <>
            <b>€€€</b>€
          </>
        );
    }
    return (
      <>
        <b>€€€€</b>
      </>
    );
  };

  const onSearchChange = (e: any) => {
    setSearch(e.target.value);
  };

  const onFormQuantityChange = (values: any) => {
    setIsQuantityEdit(values.quantity !== part?.quantity);
  };

  const onFormQuantitySubmit = async (values: any) => {
    setLoadingQuantity(true);
    try {
      const rs = await partServices.updateQuantity({
        id: part?.id,
        quantity: values.quantity,
      });
      setPart(rs);
      setIsQuantityEdit(false);
      if (adaptForm) {
        adaptForm.current?.updateAdapt(rs.unitPrice);
      }
      if (onQuantityChange) onQuantityChange(values.quantity);
    } catch (error) {}
    setLoadingQuantity(false);
  };

  const onDeletePart = async () => {
    setDeleting(true);
    try {
      if (part) {
        await partServices.deletePart(part?.id);
        dispatch(projectActions.deletePart(part?.id));
      }
      if (file) {
        dispatch(projectActions.deleteUploadPart(file.uid));
      }
      setOpenDelete(false);
      setDeleted(true);
    } catch (error) {}
    setDeleting(false);
  };

  const onSetMaterialbyRobot = () => {
    if (techDrawingData?.materialId) getMaterial(techDrawingData?.materialId);
  };

  const downloadCadFile = async () => {
    if (part) {
      try {
        await partServices.downloadCadFile({
          id: part.id,
          name: part.partFileName,
        });
      } catch (error) {}
    }
  };

  const onPriceAdapted = (part: any) => {
    setPart({ ...part });
    if (adaptForm) {
      adaptForm.current?.updateAdapt(part.adaptedUnitPrice);
    }
  };

  const onQuantitySelected = (value: number) => {
    if (viewMode == PartViewMode.EDIT) {
      partForm.setFieldValue("quantity", value);
    }
    if (viewMode == PartViewMode.UPDATED || viewMode == PartViewMode.ADAPT) {
      onFormQuantitySubmit({ quantity: value });
    }
  };

  useEffect(() => {
    if (project?.archived == true) {
      setViewMode(PartViewMode.READ_ONLY);
    }
  }, [project?.archived]);

  const showDeleteButton =
    error ||
    (uploaded &&
      ((viewMode === PartViewMode.ADAPT && project?.selfCalculation) ||
        (viewMode !== PartViewMode.CHECKOUT &&
          auth.user?.sub === project?.createdBy?.email)));

  const showCalculationButton = uploaded && viewMode === PartViewMode.EDIT;
  const showActionButton =
    viewMode !== PartViewMode.READ_ONLY &&
    part &&
    ((showDeleteButton && !showCalculationButton) || !(part?.totalPrice > 0));

  if (deleted) return <></>;

  return (
    <>
      {contextHolder}
      <div {...viewProps} className={`card-part ${props.className}`}>
        <div className={`b-calculate ${parameterUploading && "active"}`}>
          <div className="text-center">
            <SettingOutlined style={{ fontSize: "4rem" }} spin />
            <p className="mt-2">{t("calculating")}...</p>
          </div>
        </div>

        <div className="row">
          {(uploading || part || error) && (
            <div className="part-b-left col-auto d-flex flex-column align-items-center px-4">
              {uploading && !part && (
                <Skeleton.Image
                  active={true}
                  style={{ width: "12rem", height: "12rem" }}
                />
              )}
              {part && (
                <PartImage
                  wrapperStyle={{ fontSize: "3rem" }}
                  src={
                    part?.preview2dUrl ||
                    part?.dxfFileUrl ||
                    part?.previewPngUrl
                  }
                  part={part}
                  width={"100%"}
                  height={"12rem"}
                  showPartName={true}
                  readonly={auth.user?.sub !== part?.createdBy?.email}
                />
              )}
              {part &&
                (part.cuttingType === CuttingType.SHEET_METAL_STP ||
                  part.cuttingType === CuttingType.SHEET_METAL_DXF ||
                  part.cuttingType === CuttingType.SHEET_METAL_DWG) && (
                  <>
                    {part.length && (
                      <p className="mt-2 mb-0">
                        <span className="text-label">{t("length")}:</span>{" "}
                        <NumberFormat value={part.length} /> mm
                      </p>
                    )}
                    {part.height && (
                      <p className="mb-0">
                        <span className="text-label">{t("height")}:</span>{" "}
                        <NumberFormat value={part.height} /> mm
                      </p>
                    )}
                  </>
                )}
              {part?.previewUnfoldingUrl && (
                <a
                  className="text-decoration-none mt-2"
                  onClick={setIsOpenUnfoldingPreview.bind(null, true)}
                >
                  <SheetMetalUnfold />
                  &nbsp;{t("part.unfoldingPreview")}
                </a>
              )}
              <label className="mt-3 mb-3 fw-bold">
                <a
                  className="text-decoration-none"
                  // href={`${process.env.REACT_APP_API_URL}${part.partFilePath}`}
                  title={part?.partFileName || file?.name}
                  target="_blank"
                  rel="noreferrer"
                  onClick={downloadCadFile}
                >
                  <CadFileIcon /> {part?.partFileName || file?.name}
                </a>
              </label>
              {part && part.pdfFileName && (
                <label className="mb-2">
                  <a
                    className="text-decoration-none"
                    href={part.technicalDrawingUrl}
                    title={part.pdfFileName}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <PdfFileIcon /> {part.pdfFileName}
                  </a>
                </label>
              )}
              {config.shopType === ShopType.BOTH && (
                <p className="mt-1 mb-0">
                  <label className="ms-auto">
                    {t(`cuttingType.${part?.cuttingType || cuttingType}`)}
                  </label>
                </p>
              )}
            </div>
          )}
          <div className="col part-info px-md-4">
            <>
              <div className="d-flex flex-column flex-md-row mb-3">
                {auth.user?.role === UserRole.SELLER &&
                  viewMode !== PartViewMode.EDIT && (
                    <>
                      {part.cuttingType === CuttingType.MILLING_AND_TURNING && (
                        <>
                          <MiniReport
                            partId={part?.id}
                            projectId={projectId}
                            onAdapted={onPriceAdapted}
                            onCancel={setIsOpenMiniReport.bind(null, false)}
                            readonly={viewMode === PartViewMode.READ_ONLY}
                          />
                          <Button
                            className="ms-md-3"
                            onClick={setIsProcessingReport.bind(null, true)}
                          >
                            <FileTextOutlined /> {t("part.proccessingReport")}
                          </Button>
                        </>
                      )}
                      {emissionReport && (
                        <Button
                          className="ms-md-3"
                          onClick={setIsEmissionReport.bind(null, true)}
                        >
                          <FileTextOutlined /> {t("part.emissionReport")}
                        </Button>
                      )}
                      {(part?.cuttingType === CuttingType.SHEET_METAL_STP ||
                        part?.cuttingType === CuttingType.SHEET_METAL_DXF ||
                        part?.cuttingType === CuttingType.SHEET_METAL_DWG) && (
                        <CostReportModal
                          partId={part?.id}
                          projectId={projectId}
                          onAdapted={onPriceAdapted}
                          readonly={viewMode === PartViewMode.READ_ONLY}
                        ></CostReportModal>
                      )}
                    </>
                  )}
                {viewMode === PartViewMode.EDIT && (
                  <Upload {...upload2DProps} className="me-auto">
                    <Button
                      type="default"
                      icon={
                        file2D?.name || part?.pdfFileName ? (
                          <PdfFileIcon width={16} height={16} />
                        ) : (
                          <UploadOutlined />
                        )
                      }
                      loading={uploadingTechDrawingFile}
                      title={
                        file2D?.name ||
                        part?.pdfFileName ||
                        t("part.addDrawing") ||
                        ""
                      }
                    >
                      <label className="ms-2 text-ml-200px">
                        <span>
                          {file2D?.name ||
                            part?.pdfFileName ||
                            t("part.addDrawing")}
                        </span>
                      </label>
                    </Button>
                  </Upload>
                )}
                {errorTechDrawingData && (
                  <Alert
                    type="error"
                    message={errorTechDrawingData}
                    showIcon
                    className="ms-3"
                  />
                )}
                {showDeleteButton &&
                  viewMode === PartViewMode.EDIT &&
                  (part?.unitPrice || !part?.auto) && (
                    <Button
                      type="default"
                      className="ms-auto"
                      icon={<DeleteOutlined />}
                      onClick={showDeleteModal}
                      danger
                    >
                      {t("delete")}
                    </Button>
                  )}
                {showCalculationButton && (
                  <div className="d-flex">
                    {(part?.totalPrice > 0 || !part.auto) && (
                      <Form.Item className="mb-0 ms-3">
                        <Button
                          type="default"
                          onClick={onEditCancel}
                          disabled={requestingTechDrawingData}
                        >
                          {t("cancel")}
                        </Button>
                      </Form.Item>
                    )}
                    <Form.Item className="mb-0 ms-3">
                      <Button
                        type="primary"
                        htmlType="submit"
                        icon={<SettingOutlined spin={parameterUploading} />}
                        // loading={parameterUploading}
                        disabled={requestingTechDrawingData}
                        onClick={partForm.submit}
                      >
                        {t("calculate")}
                      </Button>
                    </Form.Item>
                  </div>
                )}
                {uploaded &&
                  viewMode !== PartViewMode.READ_ONLY &&
                  viewMode !== PartViewMode.EDIT &&
                  (viewMode !== PartViewMode.ADAPT ||
                    project?.selfCalculation) && (
                    <div className="ms-md-auto mb-3 mb-md-0">
                      <Button
                        className="ms-md-3"
                        type="primary"
                        icon={<EditOutlined />}
                        onClick={onEdit}
                      >
                        {t("edit")}
                      </Button>
                    </div>
                  )}
                {showActionButton && (
                  <div className="ms-3" onClick={disableClickEvent}>
                    <DropdownActions
                      project={project}
                      part={part}
                      onSubmitted={onActionsSubmmitted}
                      hiddenViewBtn
                      hiddenDeleteBtn={false}
                      onDeleteClick={showDeleteModal}
                    />
                  </div>
                )}
              </div>
              {!(
                viewMode === PartViewMode.READ_ONLY &&
                auth.user?.role === UserRole.BUYER
              ) && <hr />}
            </>

            {(!uploaded || viewMode === PartViewMode.EDIT) && (
              <Form
                form={partForm}
                layout="vertical"
                onFinish={updateParameters}
                onValuesChange={onPartChange}
                disabled={parameterUploading}
                initialValues={
                  part || {
                    materialProvided: false,
                    techDrawingFile: null,
                    numberOfFits: 0,
                    numberOfThreads: 0,
                    countersink: 0,
                    thickness: 1,
                    quantity: 1,
                    rollingDirection: RollingDirection.NO_PREFERENCE,
                    surfaceQuality: SurfaceQuality.SETTLED,
                    laserGas: LaserGas.N2,
                  }
                }
              >
                <Form.Item name="techDrawingFile" className="mb-0" hidden>
                  <Input />
                </Form.Item>
                <div>
                  {error && (
                    <Alert
                      className="mb-3"
                      message={error.message}
                      type="error"
                      icon={<MinusCircleOutlined />}
                      showIcon
                    />
                  )}

                  <Form.Item name="partId" hidden>
                    <Input />
                  </Form.Item>
                  <Form.Item name="materialGroupId" hidden>
                    <Input />
                  </Form.Item>
                  {(!error || uploaded) && (
                    <>
                      <div className="row">
                        <>
                          <div className="col col-12 col-lg-4">
                            <Form.Item
                              className="mb-0"
                              label={t("material")}
                              name="materialId"
                              rules={[{ required: true }]}
                            >
                              <div className="d-flex align-items-center">
                                <a className="text-primary" onClick={showModal}>
                                  {material ? (
                                    <>
                                      {material?.name} (
                                      <LocalizationName
                                        localizations={
                                          materialGroup?.localizations
                                        }
                                        valueDefault={materialGroup?.name}
                                      />
                                      )
                                    </>
                                  ) : (
                                    t("pleaseSelect")
                                  )}
                                </a>
                                &nbsp;
                                {requestingTechDrawingData ||
                                !!techDrawingData?.materialId ? (
                                  <RobotCalc
                                    className="pointer"
                                    loading={requestingTechDrawingData}
                                    completed={
                                      !requestingTechDrawingData &&
                                      techDrawingData?.materialId ===
                                        material?.id
                                    }
                                    error={errorTechDrawingData}
                                    onClick={onSetMaterialbyRobot}
                                    message={t("part.robot.material.message")}
                                  />
                                ) : null}
                              </div>
                            </Form.Item>
                            <Form.Item
                              className="mb-0"
                              name="materialProvided"
                              valuePropName="checked"
                            >
                              <Checkbox>
                                {t("part.form.materialProvided")}
                              </Checkbox>
                            </Form.Item>
                          </div>
                          {(cuttingType === CuttingType.SHEET_METAL_DXF ||
                            cuttingType === CuttingType.SHEET_METAL_DWG) && (
                            <div className="col col-12 col-md-2">
                              <Form.Item
                                label={t("part.form.thickness")}
                                name="thickness"
                                rules={[{ required: true }]}
                              >
                                <InputNumber type="number" min={0.001} />
                              </Form.Item>
                            </div>
                          )}
                          <div
                            className={`col col-12 ${
                              referenceCosts ? "col-lg" : "col-lg-2"
                            } d-flex gap-2`}
                          >
                            <Space direction="vertical">
                              <Form.Item
                                label={
                                  <>
                                    {t("quantity")}&nbsp;
                                    {!referenceCostsLoading &&
                                      referenceCosts && (
                                        <Popover
                                          className="icon-reference"
                                          placement="right"
                                          content={
                                            <PriceReference
                                              data={referenceCosts}
                                            />
                                          }
                                          trigger="hover"
                                        >
                                          <InfoCircleOutlined />
                                        </Popover>
                                      )}
                                    {referenceCostsLoading && (
                                      <LoadingOutlined />
                                    )}
                                  </>
                                }
                                name="quantity"
                                messageVariables={{
                                  label: t("quantity"),
                                }}
                                rules={[{ required: true }]}
                              >
                                <InputNumber type="number" min={1} />
                              </Form.Item>
                            </Space>
                            {/* {referenceCosts && (
                              <div>
                                <PriceReference
                                  data={referenceCosts}
                                  layout="light"
                                  displayKeys={["5", "10", "20"]}
                                  onQuantitySelected={onQuantitySelected}
                                />
                              </div>
                            )} */}
                          </div>

                          <div className="col col-12 col-md">
                            <Form.Item
                              name="surfaceTreatmentIds"
                              label={t("part.surfaceTreatments")}
                            >
                              <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) =>
                                  option.props.children
                                    .toLowerCase()
                                    .indexOf(input.trim().toLowerCase()) >= 0
                                }
                                mode="multiple"
                                placeholder={t("pleaseSelect")}
                                style={{ width: "100%" }}
                                disabled={
                                  requestingTechDrawingData || !material
                                }
                              >
                                {surfaceTreatments.map((item) => (
                                  <Option
                                    key={`surface-treatment-${item.id}`}
                                    value={item.id}
                                  >
                                    <LocalizationName
                                      localizations={item?.localizations}
                                      valueDefault={item.method}
                                    />
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                        </>
                      </div>
                      <div>
                        <hr />
                        {cuttingType === CuttingType.MILLING_AND_TURNING && (
                          <label className="fw-bold mb-2">
                            {t("part.tolerances")}{" "}
                            <Popover
                              placement="right"
                              title={`${t("example")}: ${t("part.tolerances")}`}
                              content={renderToleranzen}
                              trigger="hover"
                            >
                              <InfoCircleOutlined />
                            </Popover>
                          </label>
                        )}
                        <div className="row">
                          {cuttingType === CuttingType.MILLING_AND_TURNING && (
                            <>
                              <Form.Item name="countersink" hidden>
                                <Input />
                              </Form.Item>
                              {/* <Form.Item name="threadCutting" hidden>
                              <Input />
                            </Form.Item> */}
                              <div className="col col-12 col-md-6 col-lg-2">
                                {!project?.selfCalculation &&
                                  (requestingTechDrawingData ||
                                    techDrawingData?.numOfThreads != null) && (
                                    <Form.Item
                                      label={t("part.form.numberOfThreads")}
                                      name="numberOfThreads"
                                      rules={[{ required: true }]}
                                    >
                                      <RobotCalc
                                        loading={requestingTechDrawingData}
                                        completed={
                                          !requestingTechDrawingData &&
                                          techDrawingData?.numOfThreads ==
                                            partForm.getFieldValue(
                                              "numberOfThreads"
                                            )
                                        }
                                        error={errorTechDrawingData}
                                      />
                                    </Form.Item>
                                  )}
                                {(project?.selfCalculation ||
                                  !(
                                    requestingTechDrawingData ||
                                    techDrawingData?.numOfThreads != null
                                  )) && (
                                  <Form.Item
                                    label={t("part.form.numberOfThreads")}
                                    name="numberOfThreads"
                                    rules={[{ required: true }]}
                                  >
                                    <InputNumber
                                      style={{ width: "7rem" }}
                                      type="number"
                                      min={0}
                                      // disabled={
                                      //   requestingTechDrawingData ||
                                      //   part?.techDrawingData?.numOfThreads !=
                                      //     null
                                      // }
                                      addonAfter={
                                        requestingTechDrawingData ||
                                        techDrawingData?.numOfThreads !=
                                          null ? (
                                          <RobotCalc
                                            loading={requestingTechDrawingData}
                                            completed={
                                              !requestingTechDrawingData &&
                                              techDrawingData?.numOfThreads ==
                                                partForm.getFieldValue(
                                                  "numberOfThreads"
                                                )
                                            }
                                            error={errorTechDrawingData}
                                          />
                                        ) : null
                                      }
                                    />
                                  </Form.Item>
                                )}
                              </div>
                              <div className="col col-12 col-md-6 col-lg-3">
                                {!project?.selfCalculation &&
                                  (requestingTechDrawingData ||
                                    techDrawingData?.numOfTolerances !=
                                      null) && (
                                    <Form.Item
                                      label={t("part.form.numberOfFits")}
                                      name="numberOfFits"
                                      rules={[{ required: true }]}
                                    >
                                      <RobotCalc
                                        loading={requestingTechDrawingData}
                                        completed={
                                          !requestingTechDrawingData &&
                                          techDrawingData?.numOfTolerances ==
                                            partForm.getFieldValue(
                                              "numberOfFits"
                                            )
                                        }
                                        error={errorTechDrawingData}
                                      />
                                    </Form.Item>
                                  )}
                                {(project?.selfCalculation ||
                                  !(
                                    requestingTechDrawingData ||
                                    techDrawingData?.numOfTolerances != null
                                  )) && (
                                  <Form.Item
                                    label={t("part.form.numberOfFits")}
                                    name="numberOfFits"
                                    rules={[{ required: true }]}
                                  >
                                    <InputNumber
                                      style={{ width: "7rem" }}
                                      type="number"
                                      min={0}
                                      // disabled={
                                      //   requestingTechDrawingData ||
                                      //   part?.techDrawingData?.numOfTolerances !=
                                      //     null
                                      // }
                                      addonAfter={
                                        requestingTechDrawingData ||
                                        techDrawingData?.numOfTolerances !=
                                          null ? (
                                          <RobotCalc
                                            loading={requestingTechDrawingData}
                                            completed={
                                              !requestingTechDrawingData &&
                                              techDrawingData?.numOfTolerances ==
                                                partForm.getFieldValue(
                                                  "numberOfFits"
                                                )
                                            }
                                            error={errorTechDrawingData}
                                          />
                                        ) : null
                                      }
                                    />
                                  </Form.Item>
                                )}
                              </div>
                              {
                                <div className="col col-12 col-lg-3 mb-3 mb-lg-0">
                                  <div className="mb-2 mb-lg-3 text-label">
                                    {t("part.form.hasTolerances")}
                                  </div>
                                  <Space.Compact>
                                    <Form.Item
                                      name="hasTolerances"
                                      label={t("part.form.hasTolerances")}
                                      valuePropName="checked"
                                      noStyle
                                      hidden={
                                        requestingTechDrawingData ||
                                        techDrawingData?.hasSmallTolerance !=
                                          null
                                      }
                                    >
                                      <Switch
                                        checkedChildren={t("yes")}
                                        unCheckedChildren={t("no")}
                                        disabled={
                                          requestingTechDrawingData ||
                                          (disableHasTolerences &&
                                            (!project?.selfCalculation ||
                                              !dataPartForm.numberOfFits))
                                        }
                                      />
                                    </Form.Item>
                                    &nbsp;
                                    {requestingTechDrawingData ||
                                    techDrawingData?.hasSmallTolerance !=
                                      null ? (
                                      <RobotCalc
                                        loading={requestingTechDrawingData}
                                        completed={
                                          !requestingTechDrawingData &&
                                          techDrawingData?.hasSmallTolerance ==
                                            dataPartForm.hasTolerances
                                        }
                                        error={errorTechDrawingData}
                                      />
                                    ) : null}
                                  </Space.Compact>
                                </div>
                              }
                              <div className="col col-12 col-lg-4">
                                <Form.Item
                                  name="surfaceQuality"
                                  label={t("part.surfaceQuality")}
                                  rules={[{ required: true }]}
                                >
                                  <Select options={surfaceFinishs}></Select>
                                </Form.Item>
                              </div>
                            </>
                          )}
                          {(cuttingType === CuttingType.SHEET_METAL_STP ||
                            cuttingType === CuttingType.SHEET_METAL_DXF ||
                            cuttingType === CuttingType.SHEET_METAL_DWG) && (
                            <>
                              {(cuttingType === CuttingType.SHEET_METAL_DXF ||
                                cuttingType ===
                                  CuttingType.SHEET_METAL_DWG) && (
                                <div className="col col-12 col-md-6 col-lg-4">
                                  <Form.Item
                                    className="mb-0"
                                    name="dxfLayers"
                                    label={t("part.cuttingLayers")}
                                    rules={[{ required: true }]}
                                  >
                                    <DxfSelectLayer
                                      part={part}
                                      selectedLayers={
                                        selectedLayers || part?.dxfLayers
                                      }
                                      onSelectedLayers={onSelectedLayers}
                                    />
                                  </Form.Item>
                                </div>
                              )}
                              {materialGroup?.abbr == "St" && (
                                <div className="col col-12 col-md-6 col-lg-2">
                                  <Form.Item
                                    className="mb-0"
                                    label={t("part.form.laserGas")}
                                    name="laserGas"
                                    rules={[{ required: true }]}
                                  >
                                    <Select
                                      // options={laserGases}
                                      style={{
                                        width: "120px",
                                      }}
                                    >
                                      <Option
                                        value={LaserGas.N2}
                                        disabled={
                                          (partForm?.getFieldValue(
                                            "thickness"
                                          ) ||
                                            part?.thickness ||
                                            0) > 8
                                        }
                                      >
                                        Nitrogen
                                      </Option>
                                      <Option value={LaserGas.O2}>
                                        Oxygen
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </div>
                              )}
                              <div className="col col-12 col-md-6 col-lg-2">
                                <Form.Item
                                  className="mb-0"
                                  label={t("part.form.rollingDirection")}
                                  name="rollingDirection"
                                  rules={[{ required: true }]}
                                >
                                  <Select
                                    options={rollingDirections}
                                    style={{
                                      width: "120px",
                                    }}
                                  ></Select>
                                </Form.Item>
                                <Form.Item
                                  className="mb-0"
                                  name="deburring"
                                  valuePropName="checked"
                                >
                                  <Checkbox>
                                    {t("part.form.deburring")}
                                  </Checkbox>
                                </Form.Item>
                              </div>
                              <div className="col col-12 col-md-6 col-lg-2">
                                <Form.Item
                                  label={t("part.form.countersink")}
                                  name="countersink"
                                  rules={[{ required: true }]}
                                >
                                  <InputNumber type="number" min={0} />
                                </Form.Item>
                              </div>
                              <div className="col col-12 col-md-6 col-lg-2">
                                <Form.Item
                                  label={t("part.form.threadCutting")}
                                  name="numberOfThreads"
                                  rules={[{ required: true }]}
                                >
                                  <InputNumber type="number" min={0} />
                                </Form.Item>
                              </div>
                            </>
                          )}
                          <div className="col-auto">
                            <Form.Item
                              label={t("part.form.additionalComments")}
                              name="additionalComments"
                            >
                              <TextArea maxLength={255} />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </Form>
            )}
            {viewMode !== PartViewMode.EDIT && (
              <>
                <div className="">
                  {!part?.auto && (
                    <Alert
                      className="mb-3"
                      message={
                        <>
                          {auth.user?.role === UserRole.BUYER
                            ? t("project.manualNote")
                            : t("project.manual.seller.part.note")}
                          {t(
                            `part.manual.${auth.user?.role || "buyer"}.${
                              part.manualReason
                            }`
                          ) &&
                            t(
                              `part.manual.${auth.user?.role || "buyer"}.${
                                part.manualReason
                              }`,
                              {
                                defaultValue: "",
                              }
                            ) && (
                              <>
                                <br />
                                {t("reason")}:{" "}
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: t(
                                      `part.manual.${
                                        auth.user?.role || "buyer"
                                      }.${part.manualReason}`,
                                      {
                                        email: config.sellerMail,
                                      }
                                    ),
                                  }}
                                ></span>
                              </>
                            )}
                        </>
                      }
                      type="info"
                      icon={<InfoCircleOutlined />}
                      showIcon
                    />
                  )}
                  {!checkDesignPrinciple && (
                    <Alert
                      className="mb-3"
                      message={t("part.checkDesignPrinciple")}
                      type="warning"
                      icon={<WarningOutlined />}
                      showIcon
                    />
                  )}
                  <div className="row">
                    <div
                      className={`col col-12 ${
                        part?.cuttingType === CuttingType.MILLING_AND_TURNING
                          ? "col-md-6"
                          : "col-md-6"
                      }`}
                    >
                      <div className="row justify-content-between">
                        <div className="col-6 d-flex flex-column b-field">
                          <label>{t("material")}:</label>
                          <div className="d-flex">
                            {part?.material?.name}/{part?.material?.number}
                            &nbsp;
                            {!!techDrawingData?.materialId && (
                              <RobotCalc
                                loading={requestingTechDrawingData}
                                completed={
                                  techDrawingData?.materialId === material?.id
                                }
                                error={errorTechDrawingData}
                                message={t("part.robot.material.message")}
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-6 d-flex flex-column b-field">
                          <div className="row">
                            <div className="d-flex flex-column col-auto">
                              <label>{t("quantity")}:</label>
                              {(viewMode === PartViewMode.CALCULATED ||
                                viewMode === PartViewMode.READ_ONLY ||
                                viewMode === PartViewMode.ADAPT ||
                                viewMode === PartViewMode.UPDATED) && (
                                <label>
                                  {!referenceCosts && part?.quantity}
                                  {!referenceCostsLoading && referenceCosts && (
                                    <>
                                      {" "}
                                      <Popover
                                        placement="right"
                                        content={
                                          <PriceReference
                                            data={referenceCosts}
                                          />
                                        }
                                        trigger="hover"
                                      >
                                        {part?.quantity} <InfoCircleOutlined />
                                      </Popover>
                                    </>
                                  )}
                                  {referenceCostsLoading && <LoadingOutlined />}
                                </label>
                              )}
                              {viewMode === PartViewMode.CHECKOUT && (
                                <Form
                                  id="formQuantity"
                                  disabled={loadingQuantity}
                                  initialValues={part}
                                  className="ms-auto"
                                  onValuesChange={onFormQuantityChange}
                                  onFinish={onFormQuantitySubmit}
                                >
                                  <Space.Compact>
                                    <Form.Item
                                      name="quantity"
                                      className="mb-0"
                                      required
                                    >
                                      <InputNumber
                                        style={{ width: "100px" }}
                                        addonBefore={
                                          referenceCosts && (
                                            <Popover
                                              placement="right"
                                              content={
                                                <PriceReference
                                                  data={referenceCosts}
                                                />
                                              }
                                              trigger="hover"
                                            >
                                              <InfoCircleOutlined />
                                            </Popover>
                                          )
                                        }
                                      />
                                    </Form.Item>
                                    <Form.Item className="mb-0">
                                      <Button
                                        type="primary"
                                        htmlType="submit"
                                        icon={<CheckOutlined />}
                                        disabled={!isQuantityEdit}
                                        loading={loadingQuantity}
                                      />
                                    </Form.Item>
                                  </Space.Compact>
                                </Form>
                              )}
                            </div>
                            {/* <div className="col">
                              {((viewMode === PartViewMode.ADAPT &&
                                project.selfCalculation) ||
                                viewMode === PartViewMode.UPDATED) &&
                                referenceCosts && (
                                  <div>
                                    <PriceReference
                                      data={referenceCosts}
                                      layout="light"
                                      displayKeys={["5", "10", "20"]}
                                      onQuantitySelected={onQuantitySelected}
                                    />
                                  </div>
                                )}
                            </div> */}
                          </div>
                        </div>
                        <div
                          className={`col ${
                            part?.cuttingType === CuttingType.SHEET_METAL_DXF ||
                            part?.cuttingType === CuttingType.SHEET_METAL_DWG
                              ? "col-7"
                              : "col-12"
                          } d-flex flex-column b-field`}
                        >
                          <label>{t("part.surfaceTreatments")}:</label>
                          <label className="d-table">
                            {!!part?.surfaceTreatments?.length
                              ? part?.surfaceTreatments.map((s) => (
                                  <Tag
                                    key={`surface-${s.id}`}
                                    color="#FEF2C3"
                                    className="mb-2"
                                  >
                                    <span className="text-black">
                                      <LocalizationName
                                        localizations={s?.localizations}
                                        valueDefault={s.method}
                                      />
                                    </span>
                                  </Tag>
                                ))
                              : "None"}
                          </label>
                        </div>
                        {(isDxf(part.partFileName) ||
                          isDwg(part.partFileName)) && (
                          <div className="col d-flex flex-column b-field">
                            <label>{t("part.cuttingLayers")}:</label>
                            <label className="d-table">
                              {(!part?.dxfLayers ||
                                Object.keys(part?.dxfLayers).length === 0) && (
                                <Tag color="#FEF2C3">
                                  <span className="text-black">
                                    {t("part.allLayers")}
                                  </span>
                                </Tag>
                              )}
                              {Object.keys(part?.dxfLayers || {})?.map(
                                (l, i) => (
                                  <Tag
                                    key={`${part.id}-layers-${i}`}
                                    color="#FEF2C3"
                                  >
                                    <span className="text-black">{l}</span>
                                  </Tag>
                                )
                              )}
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                    {part?.cuttingType === CuttingType.MILLING_AND_TURNING && (
                      <>
                        <div className="col">
                          <div className="col d-flex flex-column b-field">
                            <label>{t("part.numberOfThreads")}:</label>
                            <div className="d-flex">
                              {(project?.selfCalculation ||
                                techDrawingData?.numOfThreads == null) && (
                                <span className="me-1">
                                  {part?.numberOfThreads}
                                </span>
                              )}
                              {techDrawingData?.numOfThreads != null ? (
                                <RobotCalc
                                  loading={requestingTechDrawingData}
                                  completed={
                                    techDrawingData.numOfThreads != null &&
                                    techDrawingData.numOfThreads ===
                                      part.numberOfThreads
                                  }
                                  error={errorTechDrawingData}
                                />
                              ) : null}
                            </div>
                          </div>
                          <div className="col d-flex flex-column b-field">
                            <label>{t("part.surfaceQuality")}:</label>
                            <label>
                              {t(`surfaceQuality.${part.surfaceQuality}`)}
                            </label>
                          </div>
                        </div>
                        <div className="col-auto">
                          <div className="d-flex flex-column b-field">
                            <label>{t("part.numberOfFits")}:</label>
                            <div className="d-flex">
                              {(project?.selfCalculation ||
                                techDrawingData?.numOfTolerances == null) && (
                                <span className="me-1">
                                  {part?.numberOfFits}
                                </span>
                              )}
                              {techDrawingData?.numOfTolerances != null ? (
                                <RobotCalc
                                  loading={requestingTechDrawingData}
                                  completed={
                                    techDrawingData.numOfTolerances != null &&
                                    techDrawingData.numOfTolerances ===
                                      part.numberOfFits
                                  }
                                  error={errorTechDrawingData}
                                />
                              ) : null}
                            </div>
                          </div>
                          <div className="d-flex flex-column b-field">
                            <label>{t("part.hasTolerances")}:</label>
                            <label className="d-flex">
                              {techDrawingData?.hasSmallTolerance == null && (
                                <>
                                  {part?.hasTolerances ? t("yes") : t("no")}
                                  &nbsp;
                                </>
                              )}

                              {techDrawingData?.hasSmallTolerance != null ? (
                                <RobotCalc
                                  loading={requestingTechDrawingData}
                                  completed={
                                    techDrawingData?.hasSmallTolerance ===
                                    part?.hasTolerances
                                  }
                                  error={errorTechDrawingData}
                                />
                              ) : null}
                            </label>
                          </div>
                        </div>
                      </>
                    )}
                    {(part?.cuttingType === CuttingType.SHEET_METAL_STP ||
                      part?.cuttingType === CuttingType.SHEET_METAL_DXF ||
                      part?.cuttingType === CuttingType.SHEET_METAL_DWG) && (
                      <>
                        <div className="col">
                          <div className="col d-flex flex-column b-field">
                            <label>{t("part.form.rollingDirection")}:</label>
                            <label>
                              {t(`rollingDirection.${part?.rollingDirection}`)}
                            </label>
                          </div>
                          <div className="col d-flex flex-column b-field">
                            <label>{t("part.form.deburring")}:</label>
                            <label>
                              {part?.deburring ? t("yes") : t("no")}
                            </label>
                          </div>
                        </div>
                        <div className="col">
                          <div className="col d-flex flex-column b-field">
                            <label>{t("part.form.countersink")}:</label>
                            <label>{part?.countersink}</label>
                          </div>
                          <div className="col d-flex flex-column b-field">
                            <label>{t("part.form.threadCutting")}:</label>
                            <label>{part?.numberOfThreads}</label>
                          </div>
                        </div>
                        <div className="col-auto">
                          {/* <div className="col d-flex flex-column b-field">
                            <label>{t("part.form.laserGas")}:</label>
                            <label>
                              {part?.laserGas}
                            </label>
                          </div> */}
                          {(isDxf(part.partFileName) ||
                            isDwg(part.partFileName)) && (
                            <div className="col d-flex flex-column b-field">
                              <label>{t("part.form.thickness")}:</label>
                              <label>{part?.thickness}</label>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <div>
                    <hr />
                    <div className="row">
                      <div className="col col-12 col-md-6 d-flex flex-column b-field mb-1">
                        <label className="fw-bold">
                          {t("part.label.additionalComments")}:
                        </label>
                        <label className="text-break">
                          {part?.additionalComments || (
                            <i className="text-secondary">{t("empty")}</i>
                          )}
                        </label>
                      </div>
                      <div className="col col-12 col-md-4 ms-auto">
                        <div className="row">
                          <div
                            className={`col col-12 d-flex align-items-center b-field mb-2 ${
                              pricePreview && "text-decoration-line-through"
                            }`}
                          >
                            <label className="mb-0">
                              {t("price.unitPrice")}:
                            </label>
                            {viewMode !== PartViewMode.ADAPT && (
                              <label className="ms-auto mb-0">
                                <CurrencyFormat
                                  value={part?.unitPrice}
                                  adaptPrice={part?.adaptedUnitPrice}
                                  isHideAdapt={
                                    (project?.selfCalculation &&
                                      (!auth.isLogined ||
                                        auth.user?.role === UserRole.BUYER)) ||
                                    (!project?.selfCalculation &&
                                      project?.manual)
                                  }
                                  hiddenValue={
                                    auth.user?.role === UserRole.BUYER &&
                                    !part?.auto &&
                                    (project?.status ===
                                      ProjectStatus.IN_PROGRESS ||
                                      project?.status ===
                                        ProjectStatus.REQUESTED_OFFER)
                                  }
                                />
                              </label>
                            )}
                            {viewMode === PartViewMode.ADAPT && (
                              <PriceAdaptForm
                                ref={adaptForm}
                                part={part}
                                onAdapted={onPriceAdapted}
                                className="app-form"
                              />
                            )}
                          </div>
                          {(pricePreviewLoading || pricePreview) && (
                            <div className="col col-12 d-flex align-items-center b-field mb-2 text-primary">
                              <label className="mb-0">
                                {t("price.preview")}:
                              </label>
                              <label className="ms-auto mb-0 fw-bold">
                                {pricePreviewLoading ? (
                                  <LoadingOutlined />
                                ) : (
                                  <CurrencyFormat value={pricePreview} />
                                )}
                              </label>
                            </div>
                          )}
                          <div
                            className={`col col-12 d-flex align-items-center b-field mb-1 ${
                              pricePreview && "text-decoration-line-through"
                            }`}
                          >
                            <label className="mb-0">
                              {t("price.netTotal")}:
                            </label>
                            <h6 className="ms-auto mb-0">
                              <CurrencyFormat
                                value={part?.unitPrice * part?.quantity}
                                adaptPrice={
                                  part?.adaptedUnitPrice != null
                                    ? part.totalPrice
                                    : undefined
                                }
                                isHideAdapt={
                                  part?.unitPrice == null ||
                                  (project?.selfCalculation &&
                                    (!auth.isLogined ||
                                      auth.user?.role === UserRole.BUYER)) ||
                                  (!project?.selfCalculation && project?.manual)
                                }
                                hiddenValue={
                                  (part?.auto && part?.unitPrice == null) ||
                                  (auth.user?.role === UserRole.BUYER &&
                                    !part?.auto &&
                                    (project?.status ===
                                      ProjectStatus.IN_PROGRESS ||
                                      project?.status ===
                                        ProjectStatus.REQUESTED_OFFER))
                                }
                              />
                            </h6>
                          </div>
                          {(pricePreviewLoading || pricePreview) && (
                            <div className="col col-12 d-flex align-items-center b-field mb-2 text-primary mb-0">
                              <label className="mb-0">
                                {t("price.netTotal")}:
                              </label>
                              <h6 className="ms-auto mb-0 fw-bold">
                                {pricePreviewLoading ? (
                                  <LoadingOutlined />
                                ) : (
                                  <CurrencyFormat
                                    value={(pricePreview || 0) * part.quantity}
                                  />
                                )}
                              </h6>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="footer-part d-flex"></div>
              </>
            )}
          </div>
        </div>
      </div>
      <Modal
        className="selectMaterial"
        open={openMaterial}
        onCancel={hideModal}
        footer={null}
        // width="46vw"
      >
        <div className="row">
          <div className="col col-3 col-md-4 group pt-5">
            <Space
              direction="vertical"
              className="group-menu"
              style={{ width: "100%" }}
            >
              <Button
                key={`group-0`}
                type={!groupTabSeleted ? "default" : "text"}
                shape="round"
                block
                onClick={onMaterialGroupChange.bind(null, undefined)}
              >
                All
              </Button>
              {(filterMaterialGroups || materialGroups)
                ?.filter((m) => m.materials && m.materials.length > 0)
                .map((item) => (
                  <Button
                    key={`group-${item.id}`}
                    type={
                      materialGroup?.id === item.id
                        ? "primary"
                        : groupTabSeleted?.id === item.id
                        ? "default"
                        : "text"
                    }
                    shape="round"
                    block
                    onClick={onMaterialGroupChange.bind(null, item)}
                  >
                    <LocalizationName
                      localizations={item?.localizations}
                      valueDefault={item.name}
                    />
                  </Button>
                ))}
            </Space>
          </div>
          <div className="col col-9 col-md-8 ps-3 pe-3 pt-5">
            <Input
              placeholder="Search material"
              prefix={<SearchOutlined />}
              allowClear
              onChange={onSearchChange}
            />
            <div className="material-list">
              {groupTabSeleted && (
                <div className="group-item">
                  <p className="sub-l">
                    <LocalizationName
                      localizations={groupTabSeleted?.localizations}
                      valueDefault={groupTabSeleted?.name}
                    />
                  </p>
                  {(filterMaterials || materials).map((item, index) => (
                    <div
                      className="row material-item"
                      key={`material-${item.id}`}
                      onClick={onMaterialSelected.bind(null, item)}
                    >
                      <div className="col col-6"> {item.name}</div>
                      <div className="col">{item.number}</div>
                      {/* <div className="col col-2">{item.density}</div> */}
                      <div className="col col-2 text-center">
                        {renderPriceSymbol(item.pricePerKilo)}
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {!groupTabSeleted &&
                (filterMaterialGroups || materialGroups)?.map(
                  (g, gIndex) =>
                    !!g.materials &&
                    g.materials.length > 0 && (
                      <div className="group-item" key={`group-${gIndex}`}>
                        <p className="sub-l">
                          <LocalizationName
                            localizations={g?.localizations}
                            valueDefault={g?.name}
                          />
                        </p>
                        {g.materials.map((item) => (
                          <div
                            className="row material-item"
                            key={`material-${item.id}`}
                            onClick={onMaterialSelected.bind(null, item)}
                          >
                            <div className="col col-6">{item.name}</div>
                            <div className="col">{item.number}</div>
                            {/* <div className="col col-2">{item.density}</div> */}
                            <div className="col col-2 text-center">
                              {renderPriceSymbol(item.pricePerKilo)}
                            </div>
                          </div>
                        ))}
                      </div>
                    )
                )}
            </div>
          </div>
          {/* <Row>
            <Col span={6} className="group pt-5"></Col>
            <Col span={18} className="ps-3 pe-3 pt-5"></Col>
          </Row> */}
        </div>
      </Modal>
      <Modal
        open={openDelete}
        title={`${part?.partFileName || file?.name}`}
        onCancel={cancelDeleteModal}
        onOk={onDeletePart}
        cancelButtonProps={{
          disabled: deleting,
        }}
        okButtonProps={{
          loading: deleting,
        }}
      >
        {t("part.delete.title", {
          name: part?.partFileName || file?.name,
        })}
      </Modal>
      <Modal
        open={isProcessingReport}
        title={t("project.processingReport")}
        width={"70vw"}
        className="modal-body-scroll"
        onCancel={setIsProcessingReport.bind(null, false)}
        footer={null}
      >
        <ProcessingReport partId={part?.id} />
      </Modal>
      <Modal
        open={isEmissionReport}
        title={t("part.emissionReport")}
        onCancel={setIsEmissionReport.bind(null, false)}
        footer={null}
        centered
        destroyOnClose
      >
        <div className="row">
          <div className="col col-9 text-label">
            {t("emissionReport.emission")}
          </div>
          <div className="col col-3">
            <NumberFormat value={emissionReport?.breakdown?.emission} /> kg
          </div>
          <div className="col col-9 text-label">
            {t("emissionReport.emissionBD.setup")}{" "}
          </div>
          <div className="col col-3">
            <NumberFormat value={emissionReport?.breakdown?.emissionBD?.setup} />{" "}
            kg
          </div>
          <div className="col col-9 text-label">
            {t("emissionReport.emissionBD.unit")}
          </div>
          <div className="col col-3">
            <NumberFormat value={emissionReport?.breakdown?.emissionBD?.unit} />{" "}
            kg
          </div>
          <div className="col col-9 text-label">
            {t("emissionReport.emissionBD.unitBD.rawMaterial")}
          </div>
          <div className="col col-3">
            <NumberFormat
              value={emissionReport?.breakdown?.emissionBD?.unitBD?.rawMaterial}
            />{" "}
            kg
          </div>
          <div className="col col-9 text-label">
            {t("emissionReport.emissionBD.unitBD.machining")}
          </div>
          <div className="col col-3">
            <NumberFormat
              value={emissionReport?.breakdown?.emissionBD?.unitBD?.machining}
            />{" "}
            kg
          </div>
          <div className="col col-9 text-label">
            {t("emissionReport.emissionBD.unitBD.additions")}
          </div>
          <div className="col col-3">
            <NumberFormat
              value={emissionReport?.breakdown?.emissionBD?.unitBD?.additions}
            />{" "}
            kg
          </div>
        </div>
      </Modal>
      <Modal
        open={isOpenUnfoldingPreview}
        width={"calc(60vw - 46px)"}
        onCancel={setIsOpenUnfoldingPreview.bind(null, false)}
        footer={null}
        centered
        className="unfolding-preview"
      >
        <div className="d-flex justify-content-center align-items-center">
          <img src={part?.previewUnfoldingUrl} />
        </div>
      </Modal>
    </>
  );
}

export default CadFileItem;
